// extracted by mini-css-extract-plugin
export var root = "PlasmicCaseStudy-module--root--cyyRb";
export var link = "PlasmicCaseStudy-module--link--DS+C3";
export var hero = "PlasmicCaseStudy-module--hero--g6vmY";
export var heroglobal_mode_reveal = "PlasmicCaseStudy-module--heroglobal_mode_reveal--QW6aq";
export var img__z5CWf = "PlasmicCaseStudy-module--img__z5CWf--xt0P2";
export var __wab_imgSpacer = "PlasmicCaseStudy-module--__wab_img-spacer--IxV93";
export var content = "PlasmicCaseStudy-module--content--jMKw2";
export var contentglobal_mode_reveal = "PlasmicCaseStudy-module--contentglobal_mode_reveal--OMmFd";
export var header = "PlasmicCaseStudy-module--header--7BSap";
export var title = "PlasmicCaseStudy-module--title--wNhp8";
export var text = "PlasmicCaseStudy-module--text--Yka6u";
export var h1 = "PlasmicCaseStudy-module--h1--QLuwK";
export var slotTargetTitle = "PlasmicCaseStudy-module--slotTargetTitle--tE6ym";
export var description = "PlasmicCaseStudy-module--description--FoD5k";
export var slotTargetDescription = "PlasmicCaseStudy-module--slotTargetDescription--j6p71";
export var services = "PlasmicCaseStudy-module--services--ipM5P";
export var slotTargetServices = "PlasmicCaseStudy-module--slotTargetServices---Ij0J";
export var tag__biiB9 = "PlasmicCaseStudy-module--tag__biiB9--EzBXJ";
export var text__oRvv = "PlasmicCaseStudy-module--text__oRvv--GPnuG";
export var tag__kE5P = "PlasmicCaseStudy-module--tag__kE5P--xyfeC";
export var text__oegoj = "PlasmicCaseStudy-module--text__oegoj--So+3S";
export var tag__fgfHd = "PlasmicCaseStudy-module--tag__fgfHd--mqxex";
export var text__dvlR2 = "PlasmicCaseStudy-module--text__dvlR2--AT+L3";
export var tag__hpi5N = "PlasmicCaseStudy-module--tag__hpi5N--Z52B-";
export var text__pxaQd = "PlasmicCaseStudy-module--text__pxaQd--K2ytF";
export var tag__trtnX = "PlasmicCaseStudy-module--tag__trtnX--E0BsK";
export var text___52AfD = "PlasmicCaseStudy-module--text___52AfD--KgNwZ";
export var overview = "PlasmicCaseStudy-module--overview--ddmih";
export var overviewImages = "PlasmicCaseStudy-module--overviewImages--nIslH";
export var overviewImagesglobal_mode_reveal = "PlasmicCaseStudy-module--overviewImagesglobal_mode_reveal--hvNPE";
export var freeBox___4M8J = "PlasmicCaseStudy-module--freeBox___4M8J--0caZ-";
export var img___2YcZy = "PlasmicCaseStudy-module--img___2YcZy--T5gg3";
export var img__gS99C = "PlasmicCaseStudy-module--img__gS99C--lICIh";
export var svg = "PlasmicCaseStudy-module--svg--n8voj";
export var img__lAzkm = "PlasmicCaseStudy-module--img__lAzkm--mKwZV";
export var overviewCaption = "PlasmicCaseStudy-module--overviewCaption--EYf+J";
export var overviewCaptionglobal_mode_reveal = "PlasmicCaseStudy-module--overviewCaptionglobal_mode_reveal--o3ugI";
export var slotTargetOverviewCaption = "PlasmicCaseStudy-module--slotTargetOverviewCaption--RcIiI";
export var overviewDescription = "PlasmicCaseStudy-module--overviewDescription--Enk9r";
export var overviewDescriptionglobal_mode_dark = "PlasmicCaseStudy-module--overviewDescriptionglobal_mode_dark--fr1up";
export var problem = "PlasmicCaseStudy-module--problem--+r1Dd";
export var h3__i9Mpl = "PlasmicCaseStudy-module--h3__i9Mpl---PI3D";
export var slotTargetProblem = "PlasmicCaseStudy-module--slotTargetProblem--wHfaj";
export var approach = "PlasmicCaseStudy-module--approach--af7DP";
export var h3__dcsPd = "PlasmicCaseStudy-module--h3__dcsPd--fmvCm";
export var text___9TmiC = "PlasmicCaseStudy-module--text___9TmiC--6WFfp";
export var work = "PlasmicCaseStudy-module--work--gsBaG";
export var h3___6KB0 = "PlasmicCaseStudy-module--h3___6KB0--LKGwb";
export var freeBox__zGxN9 = "PlasmicCaseStudy-module--freeBox__zGxN9--n-riw";
export var freeBox__t1Txf = "PlasmicCaseStudy-module--freeBox__t1Txf--t0U1o";
export var h4___2SBb1 = "PlasmicCaseStudy-module--h4___2SBb1--9zRKN";
export var freeBox__uWjBd = "PlasmicCaseStudy-module--freeBox__uWjBd---KX+P";
export var text___5L9Dt = "PlasmicCaseStudy-module--text___5L9Dt--FmuPm";
export var img__dbx1Y = "PlasmicCaseStudy-module--img__dbx1Y--b2Dni";
export var freeBox__pZcZ2 = "PlasmicCaseStudy-module--freeBox__pZcZ2--NFYCS";
export var text__pFzr3 = "PlasmicCaseStudy-module--text__pFzr3--25fHj";
export var freeBox__tkYh = "PlasmicCaseStudy-module--freeBox__tkYh--B95vy";
export var h4__gxcwv = "PlasmicCaseStudy-module--h4__gxcwv--iSpMe";
export var text__uOads = "PlasmicCaseStudy-module--text__uOads--Lzbo+";
export var freeBox__uDUoD = "PlasmicCaseStudy-module--freeBox__uDUoD--I9Chw";
export var h4__leY5Y = "PlasmicCaseStudy-module--h4__leY5Y--2I+Eu";
export var text__gwt9 = "PlasmicCaseStudy-module--text__gwt9--hnW+h";
export var gallery = "PlasmicCaseStudy-module--gallery---oqw9";